<template>
    <div class="footer">
        <div class="space-start">
            <div @click="openLaunchpad()" class="message">
                <el-tooltip effect="dark" content="启动台" placement="top-start">
                    <i style="background-color: #00ac97; color: #fff" class="messageicon iconfont icon-gongzuotai"></i>
                </el-tooltip>
            </div>
        </div>
        <div class="dock">
            <template v-for="item in $store.state.dockAppList" :key="item.pid">
                <div class="item" @click="openApp(item)" :class="$store.state.nowApp.pid == item.pid ? 'jump' : ''" v-if="item && tool.isAppInKeepList(item, $store.state.dockAppList)">
                    <i
                        v-if="item?.icon"
                        :style="{
                            backgroundColor: item.iconBgColor,
                            color: item.iconColor
                        }"
                        class="iconfont"
                        :class="item.icon"
                    ></i>
                    <img
                        class="iconfont"
                        v-else
                        :src="item.logo"
                        :style="{
                            backgroundColor: item.iconBgColor
                        }"
                    />
                    <div class="dot" v-if="tool.isAppInOpenList(item, $store.state.openAppList)"></div>
                    <div class="title">{{ item.title }}</div>
                </div>
            </template>
        </div>
        <div class="space-end">
            <el-tooltip effect="dark" content="电源选项" placement="top-start">
                <div class="message">
                    <el-dropdown trigger="click">
                        <i style="background-color: #333; color: #d4dbef" class="messageicon iconfont icon-tuichu"></i>

                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item>
                                    <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ userName }}</div>
                                </el-dropdown-item>
                                <el-dropdown-item class="line"></el-dropdown-item>
                                <el-dropdown-item @click="logout">
                                    <div>重启</div>
                                </el-dropdown-item>
                                <el-dropdown-item @click="shutdown">
                                    <div>关机</div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                        <!-- <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="lockScreen">
                                    <div>锁定屏幕</div>
                                </el-dropdown-item>
                                <el-dropdown-item class="line"></el-dropdown-item>
                                <el-dropdown-item @click="shutdown">
                                    <div>关机</div>
                                </el-dropdown-item>
                                <el-dropdown-item @click="logout">
                                    <div>退出登录 {{ userName }}...</div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template> -->
                    </el-dropdown>
                </div>
            </el-tooltip>
            <!-- <div class="item">
                <div class="datetime" @click="showOrHideCalendar">
                    <span class="time">{{ timeString }}</span>
                    <span class="date">{{ dateString }}</span>
                </div>
                <transition name="fade">
                    <el-calendar v-model="nowDate" v-if="isCalendarShow" ref="calendarContainer">
                        <template #header>
                            <span class="time">{{ timeString }}</span>
                            <span class="date">{{ dateString }}</span>
                        </template>
                    </el-calendar>
                </transition>
            </div> -->

            <!-- <div class="message">
                <el-tooltip effect="dark" content="消息中心" placement="top-start">
                    <i @click="showOrHideMessage" style="background-color: #00ac97; color: #fff" class="messageicon iconfont icon-xiaoxi"></i>
                </el-tooltip>
                <transition name="fade">
                    <div class="message-container" v-if="isMessageShow">
                        <div class="message-title">
                            <span>消息中心</span>
                            <i class="iconfont icon-close" @click="showOrHideMessage"></i>
                        </div>

                        <div class="message-item" @click="openDashboard()">
                            <div class="message-item-source">
                                <div class="message-item-source-title">
                                    <i style="background-color: rgb(219, 80, 72); color: rgb(255, 255, 255)" class="iconfont icon-jellyfish-glyph"></i>
                                    <span style="margin-left: 10px">数据驾驶舱</span>
                                </div>
                                <div class="message-item-source-time">2024-06-12 10:33</div>
                            </div>
                            <div class="message-item-desc">
                                <p>新的数据统计已生成，点击可进行查看</p>
                            </div>
                        </div>
                    </div>
                </transition>
            </div> -->
        </div>
    </div>
</template>
<script>
// import { onClickOutside } from '@vueuse/core';
// import { ref } from 'vue';

export default {
    // setup() {
    //     const calendarContainer = ref(null);

    //     onClickOutside(calendarContainer, (event) => console.log(event));
    //     return { calendarContainer };
    // },

    data() {
        return {
            isCalendarShow: false,
            isMessageShow: false,
            nowDate: new Date(),
            dateString: '',
            timeString: '',
            userName: ''
        };
    },
    methods: {
        /**
         * @description: 显示或隐藏系统消息
         */
        showOrHideMessage() {
            this.isMessageShow = !this.isMessageShow;
        },
        /**
         * @description: 显示或隐藏日历
         */
        showOrHideCalendar() {
            this.isCalendarShow = !this.isCalendarShow;
        },
        /**
         * @description: 打开时间计时器
         */
        startTimer() {
            setInterval(() => {
                this.dateString = this.tool.formatTime(new Date(), 'yyyy-MM-dd');
                this.timeString = this.tool.formatTime(new Date(), 'HH:mm');
            }, 1000);
        },
        openApp(item) {
            this.$store.commit('openApp', item);
        },
        openLaunchpad() {
            this.$store.commit('launchpad');
        },
        /**
         * @description: 锁定屏幕
         */
        lockScreen() {
            this.$emit('lockScreen');
        },
        /**
         * @description: 关机
         */
        shutdown() {
            this.$emit('shutdown');
        },
        /**
         * @description: 注销登录
         */
        logout() {
            this.$emit('logout');
        },
        // 打开数据驾驶舱
        openDashboard() {
            const dashbaord = this.$store.state.dockAppList.find((item) => item.key === 'ht_dashboard');
            if (dashbaord) {
                this.openApp(dashbaord);
                this.isMessageShow = false;
            }
        }
    },
    created() {
        this.startTimer();
        this.userName = localStorage.getItem('user_name') || '';

        // 自动打开消息中心
        // setTimeout(() => {
        //     this.isMessageShow = true;
        // }, 1000);
    }
};
</script>

<style scoped lang="scss">
.space-start {
    width: 200px;
    display: flex;
    justify-content: flex-start;
    .message {
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .messageicon {
            cursor: pointer;
            border-radius: 20px;
            padding: 2px;
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
            height: 30px;
            width: 30px;
            text-align: center;
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s, margin 0.3s;
        }
    }
}

.space-end {
    width: 200px;
    display: flex;
    justify-content: flex-end;

    .datetime {
        cursor: pointer;
        padding: 0px 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        .date {
            font-size: 12px;
        }
        .time {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .el-calendar {
        color: #333;
        background: rgba(255, 255, 255, 0.98);
        position: fixed;
        bottom: 60px;
        right: 0;
        width: 500px;
        border-radius: 10px;
    }

    .datetime:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .message-container {
        height: calc(100vh - 76px);
        overflow-y: auto;
        color: #fff;
        background: rgb(0 172 151 / 10%);
        position: fixed;
        bottom: 56px;
        right: 0;
        width: 400px;
        border-radius: 10px;

        .iconfont {
            border-radius: 10px;
            padding: 2px;
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
            height: 20px;
            width: 20px;
            text-align: center;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s, margin 0.3s;
        }

        .message-title {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 600;
            margin: 20px;
            .iconfont {
                cursor: pointer;
                background-color: transparent !important;
            }
        }

        .message-item {
            height: 150px;
            margin: 10px;
            background-color: rgb(0 172 151 / 20%);
            color: #fff;
            border-radius: 10px;
            cursor: pointer;

            .message-item-source {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
                padding: 10px;

                .message-item-source-title {
                    display: flex;
                    font-size: 18px;
                    font-weight: 500;
                }
                .message-item-source-time {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgb(255 255 255 / 80%);
                }
            }

            .message-item-desc {
                margin: 0 10px;
                color: rgb(255 255 255 / 70%);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3; /* 限制在3行内 */
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .message-item:hover {
            background-color: rgb(0 172 151 / 30%);
        }
    }

    .message {
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .messageicon {
            cursor: pointer;
            border-radius: 20px;
            padding: 2px;
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
            height: 30px;
            width: 30px;
            text-align: center;
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s, margin 0.3s;
        }
    }
}
.dock {
    display: flex;
    justify-content: center;
    flex: 1;
}
.footer {
    flex-direction: row;
    display: flex;
    padding: 2px;
    z-index: 99990;
    .item {
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .iconfont {
            cursor: pointer;
            border-radius: 20px;
            padding: 2px;
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
            height: 30px;
            width: 30px;
            text-align: center;
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s, margin 0.3s;
        }
    }
    .item:hover {
        .iconfont {
            transform: scale(2) translateY(-10px);
            margin: 0px 15px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        }
        .title {
            position: absolute;
            display: inherit;
            word-break: keep-all;
            background: rgba(0, 0, 0, 0.3);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 5px 10px;
            font-size: 12px;
            animation: dockTitleAnimation 0.5s ease 1 forwards;
        }
    }
    .dot {
        width: 3px;
        height: 3px;
        background: #04ff5f;
        position: absolute;
        bottom: 0px;
        border-radius: 5px;
        display: inline-block;
        font-size: 0;
    }
    .title {
        display: none;
    }
    .jump {
        animation: jumpAnimation 0.8s ease 1;
    }
}
</style>
<style>
.footer .el-calendar-day {
    height: 50px !important;
}

.footer .el-calendar__body {
    padding: 12px 20px 20px;
}
</style>
