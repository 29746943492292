<template>
    <div class="login">
        <div class="head">
            <img :src="headImg" style="width: 100%" />
        </div>
        <div class="message">欢迎使用 SD-OS</div>

        <div class="form" v-if="!isCas">
            <div class="item" v-if="!haveSavedUserName" :class="isUserNameError ? 'error' : ''">
                <input class="account" placeholder="请输入用户名" type="email" v-model="user_name" />
            </div>
            <div class="item" :class="isUserPasswordError ? 'error' : ''">
                <input class="password" placeholder="请输入密码" type="password" v-model="user_password" :class="user_password ? 'password-in' : ''" />
                <i class="login-button iconfont icon-Send" :class="user_password ? 'click-enable' : ''" @click="login"></i>
            </div>
        </div>

        <div class="loginMsg">
            <transition name="fade">
                <span v-show="isMsg">{{ loginMsg }}</span>
            </transition>
        </div>
    </div>
</template>
<script setup>
import request from '@/utils/request.js';
import headImg from '@/asset/img/logo.png';
import { ref, onMounted, onUnmounted, defineEmits } from 'vue';

const emit = defineEmits(['logined']);

const isMsg = ref(false);
const loginMsg = ref('');

// 企业微信跳转
const isCas = ref(false);

const user_name = ref('');
const user_password = ref('');
const isUserNameError = ref(false);
const isUserPasswordError = ref(false);

const getCodeData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const path = {};
    for (const [key, value] of urlParams) {
        path[key] = value;
    }
    let p = Object.keys(path)[0];
    if (p) {
        isCas.value = true;

        const params = { code: encodeURI(p.substring(2, p.length - 1)).replace(/%20/g, '+') };
        const data = {
            url: '/api/v1/user/code',
            method: 'post',
            data: params
        };
        request(data)
            .then((res) => {
                if (res.status === 200 && res.data) {
                    if (res.data && res.data.access_token) {
                        localStorage.setItem('user_name', res.data.username);
                        localStorage.setItem('access_token', res.data.access_token);
                        localStorage.setItem('role', res.data.role);
                        localStorage.setItem('token_type', res.data.token_type);
                        emit('logined');
                    } else {
                        isMsg.value = true;
                        loginMsg.value = '认证失败，请使用账号密码进行登录';
                        isCas.value = false;
                    }
                } else {
                    isMsg.value = true;
                    loginMsg.value = '认证失败，请使用账号密码进行登录';
                    isCas.value = false;
                }
            })
            .catch((e) => {
                isMsg.value = true;
                loginMsg.value = '认证失败，请使用账号密码进行登录';
                isCas.value = false;
                console.log(e);
            });
    } else {
        isCas.value = false;
    }
};

const login = () => {
    if (!user_name.value) {
        isUserNameError.value = true;
        setTimeout(() => {
            isUserNameError.value = false;
        }, 1000);
        return;
    }
    if (!user_password.value) {
        isUserPasswordError.value = true;
        setTimeout(() => {
            isUserPasswordError.value = false;
        }, 1000);
        return;
    }

    const params = new FormData();
    params.append('username', user_name.value);
    params.append('password', user_password.value);

    const data = {
        url: '/api/v1/user/token',
        method: 'post',
        data: params
    };
    request(data)
        .then((res) => {
            if (res.status === 200 && res.data) {
                if (res.data && res.data.access_token) {
                    console.log(res.data);
                    localStorage.setItem('user_name', res.data.username);
                    localStorage.setItem('access_token', res.data.access_token);
                    localStorage.setItem('role', res.data.role);
                    localStorage.setItem('token_type', res.data.token_type);
                    emit('logined');
                } else {
                    isMsg.value = true;
                    loginMsg.value = '认证失败，账号或密码错误';
                    isCas.value = false;
                }
            } else {
                isMsg.value = true;
                loginMsg.value = '认证失败，账号或密码错误';
                isCas.value = false;
            }
        })
        .catch((e) => {
            isMsg.value = true;
            loginMsg.value = '认证失败，账号或密码错误';
            isCas.value = false;
            console.log(e);
        });
};

const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
        login();
    }
};

onMounted(() => {
    // 注册快捷键打开配置窗口 及回车
    document.addEventListener('keydown', handleKeyDown);

    getCodeData();
});

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeyDown);
});
</script>
<style scoped lang="scss">
::-webkit-input-placeholder {
    color: #fff;
}

::-moz-placeholder {
    color: #fff;
}

:-ms-input-placeholder {
    color: #fff;
}
.login {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    // margin-top: -100px;
    z-index: 99999;
    backdrop-filter: blur(100px);
    .head {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 150px;
        width: 150px;
        border-radius: 100%;
        box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
        margin-top: -50px;
    }
    .message {
        margin-top: 20px;
        font-size: 20px;
        text-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
        color: #eee;
        margin-bottom: 20px;
    }
    .form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .error {
            animation: loginErrorAnimation 0.2s ease 3;
        }
        .item {
            vertical-align: middle;
            position: relative;
            width: 250px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            input {
                color: white;
                outline: none;
                border: none;
                margin: 5px;
                font-size: 16px;
                background-color: rgba(255, 255, 255, 0.3);
                padding: 8px 24px;
                border-radius: 20px;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
                width: 100%;
            }

            .password-in {
                width: 155px;
            }

            .password {
                transition: width 0.3s;
            }

            .login-button {
                position: absolute;
                top: 5px;
                right: -50px;
                transition: right 0.3s;
            }

            .iconfont {
                vertical-align: middle;
                display: inline-block;
                background-color: rgba(255, 255, 255, 0.3);
                font-size: 18px;
                border-radius: 100%;
                width: 36px;
                height: 36px;
                text-align: center;
                line-height: 36px;
                cursor: pointer;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
            }
            .iconfont:hover {
                background-color: rgba(255, 255, 255, 0.5);
            }
            .click-enable {
                right: 0;
            }
        }
    }
    .loginMsg {
        height: 24px;
        color: #fc9b2c;
    }
}
</style>
