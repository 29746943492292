<template>
    <div class="sd-os" @mousedown.self="boot" @contextmenu.prevent="onContextShow()">
        <transition name="fade">
            <Bg v-if="isBg"></Bg>
        </transition>
        <transition name="fade">
            <Loading v-if="isLoading" @loaded="loaded"></Loading>
        </transition>
        <transition name="fade">
            <Login v-if="isLogin" @logined="logined"></Login>
        </transition>
        <transition name="fade">
            <DeskTop v-if="isDeskTop" @lockScreen="lockScreen" @shutdown="shutdown" @logout="logout" @launchpad="launchpad"></DeskTop>
        </transition>
        <transition name="fade">
            <LaunchPad v-if="isLaunchPad"></LaunchPad>
        </transition>
    </div>
</template>

<style scoped lang="scss">
.sd-os {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
</style>
<script setup>
import Bg from '@/components/Bg';
import LaunchPad from '@/components/LaunchPad';
import Loading from '@/components/Loading';
import Login from '@/components/Login';
import DeskTop from '@/components/DeskTop';
import request from '@/utils/request.js';
import { ipc, isEE } from '@/utils/ipcRenderer.js';

import { ref, onMounted } from 'vue';
let isBg = ref(true);
let isLoading = ref(false);
let isLogin = ref(false);
let isDeskTop = ref(false);
let isLaunchPad = ref(false);

onMounted(() => {
    boot();
});
const onContextShow = () => {
    console.log('onContextShow');
};
const boot = () => {
    isLoading.value = true;
};
const loaded = () => {
    isLoading.value = false;
    isBg.value = true;
    isLogin.value = true;
};
const logined = () => {
    isLogin.value = false;
    isDeskTop.value = true;
};
const lockScreen = () => {
    isLogin.value = true;
};
const launchpad = (show) => {
    isLaunchPad.value = show;
};
const logout = () => {
    const token = localStorage.getItem('access_token');
    logoutApi(token);

    localStorage.removeItem('user_name');
    localStorage.removeItem('access_token');
    isDeskTop.value = false;
    isLogin.value = true;
};
const shutdown = () => {
    // 清除定时器
    clearTimeout(localStorage.getItem('timeId'));

    const token = localStorage.getItem('access_token');
    logoutApi(token);

    localStorage.removeItem('user_name');
    localStorage.removeItem('access_token');
    isDeskTop.value = false;
    isLogin.value = false;
    isLoading.value = false;
    isBg.value = false;

    // electron 通信 关闭应用窗口
    if (isEE) {
        // 定义通信频道，即路由
        const ipcApiRoute = {
            ipcSendMsg: 'controller.power.shutdown'
        };

        ipc.send(ipcApiRoute.ipcSendMsg, '');
    }
};

const logoutApi = (token) => {
    const data = {
        url: '/api/v1/user/logout',
        method: 'post',
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    request(data);
};
</script>
