<template>
    <div class="desktop">
        <div
            class="body"
            @contextmenu.prevent.self="
                hideAllController();
                openMenu($event);
            "
            @click.stop="hideAllController()"
        >
            <div class="desktop-app">
                <template v-for="item in deskTopAppList" :key="item.key">
                    <div class="app-item" v-on:click="$store.commit('openApp', item)" v-if="!item.hideInDesktop">
                        <div class="icon">
                            <i
                                v-if="item?.icon"
                                :style="{
                                    // backgroundColor: item.iconBgColor,
                                    color: item.iconColor,
                                    'font-size': '45px',
                                    padding: 0
                                }"
                                class="iconfont"
                                :class="item.icon"
                            ></i>

                            <img style="width: 45px" v-else :src="item.logo" />
                        </div>
                        <div class="title">{{ item.title }}</div>
                    </div>
                </template>
            </div>
            <transition-group name="fade-window">
                <template v-for="item in $store.state.openAppList" :key="item.pid">
                    <App v-if="!item.outLink" v-show="!item.hide" :app="item" :key="item.pid"></App>
                </template>
            </transition-group>
            <transition name="fade-menu">
                <div v-show="rightMenuVisible" :style="{ left: rightMenuLeft + 'px', top: rightMenuTop + 'px' }" class="contextmenu">
                    <div @click="lockScreen">锁定屏幕</div>
                    <hr />
                    <div @click="openAppByKey('system_setting')">系统设置</div>
                    <hr />
                    <div @click="$message.warning('即将上线，敬请期待')">设置壁纸</div>
                </div>
            </transition>
            <transition-group name="fade-widget">
                <div v-show="isWidgetShow">
                    <template v-for="item in $store.state.openWidgetList" :key="item.pid">
                        <Widget v-if="!item.outLink" v-show="!item.hide" :app="item" :key="item.pid"></Widget>
                    </template>
                </div>
            </transition-group>
        </div>
        <Dock @lockScreen="lockScreen" @shutdown="shutdown" @logout="logout"></Dock>
    </div>
</template>
<script>
import App from '@/components/App';
import Dock from '@/components/Dock';
import Widget from '@/components/Widget';
export default {
    components: {
        App,
        Dock,
        Widget
    },
    data() {
        return {
            rightMenuVisible: false,
            rightMenuLeft: 0,
            rightMenuTop: 0,
            userName: '',
            menu: [],
            timeString: '',
            deskTopAppList: [],
            deskTopMenu: [
                // {
                //   key: "desktop",
                //   title: "桌面",
                //   sub: [
                //     {
                //       key: "big_icon",
                //       title: "大图标",
                //     },
                //     {
                //       key: "big_icon",
                //       title: "小图标",
                //     },
                //     {
                //       isLine: true
                //     },
                //     {
                //       key: "align_left",
                //       title: "左侧排列",
                //     },
                //     {
                //       key: "align_right",
                //       title: "右侧排列",
                //     },
                //     {
                //       isLine: true
                //     },
                //     {
                //       key: "wall",
                //       title: "更换壁纸...",
                //     }
                //   ]
                // }
            ],
            isWidgetShow: false
        };
    },
    watch: {
        '$store.state.nowApp'() {
            this.menu = this.$store.state.nowApp.menu;
        },
        '$store.state.launchpad'() {
            this.$emit('launchpad', this.$store.state.launchpad);
        }
    },
    created() {
        this.menu = this.deskTopMenu;
        this.userName = localStorage.getItem('user_name') || '';
        this.deskTopAppList = this.tool.getDeskTopApp();
        this.$store.commit('getDockAppList');
    },
    methods: {
        /**
         * @description: 隐藏所有弹出的控制器
         */
        hideAllController() {
            this.rightMenuVisible = false;
        },
        /**
         * @description: 打开右键菜单
         */
        openMenu(e) {
            const menuMinWidth = 105;
            const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
            const offsetWidth = this.$el.offsetWidth; // container width
            const maxLeft = offsetWidth - menuMinWidth; // left boundary
            const left = e.clientX - offsetLeft; // margin right

            if (left > maxLeft) {
                this.rightMenuLeft = maxLeft;
            } else {
                this.rightMenuLeft = left;
            }

            this.rightMenuTop = e.clientY - 30;
            this.rightMenuVisible = true;
        },
        /**
         * @description: 打开指定key的应用
         */
        openAppByKey(key) {
            this.$store.commit('openAppByKey', key);
        },
        /**
         * @description: 锁定屏幕
         */
        lockScreen() {
            this.$emit('lockScreen');
        },
        /**
         * @description: 关机
         */
        shutdown() {
            this.$emit('shutdown');
        },
        /**
         * @description: 注销登录
         */
        logout() {
            this.$emit('logout');
        },
        showOrHideWidget() {
            this.isWidgetShow = !this.isWidgetShow;
        }
    }
};
</script>
<style>
.top .el-dropdown {
    color: white !important;
    height: 100% !important;
}
.top .el-calendar-day {
    height: 30px !important;
}
.top .is-today {
    background: #4b9efb !important;
    color: white !important;
}
</style>
<style scoped lang="scss">
.desktop {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: white;
    overflow: hidden;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    .top {
        height: 28px;
        // background-color: rgba(0, 0, 0, 0.3);
        // backdrop-filter: blur(20px);
        display: flex;
        flex-direction: row;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        padding: 0px 5px;
        z-index: 100;
        .logo {
            height: 100%;
            align-items: center;
            justify-content: center;
            padding: 0px 15px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .iconfont {
                font-size: 16px;
                margin-top: -3px;
            }
            .el-select {
                position: absolute;
                opacity: 0;
            }
        }

        .logo:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
        .space {
            flex-grow: 1;
        }
        .menu {
            display: flex;
            flex-direction: row;
            font-size: 13px;
            height: 100%;
            font-weight: 500;
            .item {
                font-size: 13px;
                padding: 0px 15px;
                display: inline-block;
                flex-grow: 1;
                cursor: pointer;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
            }
            .item:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        .status {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 100%;

            .audio {
                cursor: pointer;
                padding: 0px 10px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                position: relative;
                .iconfont {
                    font-size: 20px;
                }
                .el-slider {
                    position: absolute;
                    top: 40px;
                    height: 80px;
                }
            }
            .audio:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
            .datetime {
                cursor: pointer;
                padding: 0px 10px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                position: relative;
                .el-calendar {
                    color: #333;
                    background: rgba(255, 255, 255, 0.98);
                    position: fixed;
                    top: 40px;
                    right: 20px;
                    width: 500px;
                    border-radius: 10px;
                }
            }
            .datetime:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
            .notification {
                cursor: pointer;
                padding: 0px 10px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                .iconfont {
                    font-size: 20px;
                }
                .notification:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }
        }
    }

    .body {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        .desktop-app {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            padding: 20px;
            flex-wrap: wrap-reverse;
            .app-item {
                padding: 10px 0px;
                flex-direction: column;
                text-align: center;
                text-shadow: 0px 0px 2px rgb(0 0 0 / 50%);
                cursor: pointer;
                border-radius: 10px;
                border: 2px solid transparent;
                justify-content: center;
                align-items: center;
                width: 80px;
                .icon {
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }
                .iconfont {
                    font-size: 28px;
                    border-radius: 10px;
                    padding: 8px;
                }
                .title {
                    margin-top: 5px;
                    position: relative;
                    max-height: 3em;
                    overflow: hidden;
                    font-size: 12px;
                    line-height: 1.5em;
                    word-break: break-all;
                }
            }
            .app-item:hover {
                border: 2px solid rgba(255, 255, 255, 0.5);
            }
        }

        .contextmenu {
            position: absolute;
            background: rgba(255, 255, 255, 0.9);
            border-radius: 5px;
            box-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
            color: #333;
            font-size: 14px;
            text-align: left;
            width: 200px;
            overflow: hidden;
            padding: 2px 0px;
            text-shadow: none;
            z-index: 100;
            hr {
                border: none;
                border-top: 1px solid #ddd;
            }
            div {
                cursor: pointer;
                font-size: 13px !important;
                color: #333;
                border-radius: 5px;
                line-height: 2;
                padding: 0px 12px;
                display: flex;
                align-items: center;
                margin: 3px 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            div:hover {
                background: #4b9efb;
                color: white;
                border-radius: 5px;
            }
        }
    }
    .footer {
        display: flex;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(20px);
        border-radius: 10px;
        margin: 0 10px 10px;
    }
}
</style>
