export default {
    allAppList: [
        {
            key: 'sd_flowSystem',
            component: 'DemoFlowSystem',
            icon: '',
            logo: '/logo/sssb.png',
            title: '数据流量智能分析系统',
            iconColor: '#fff',
            iconBgColor: '#333',
            width: 400,
            height: 300,
            innerLink: true,
            keepInDock: true,
            url: 'https://p.sss.shining-data.com/'
            // url: 'http://localhost:18082/'
        },
        {
            key: 'sd_trackSystem',
            component: 'DemoTrackSystem',
            icon: '',
            logo: '/logo/sssa.png',
            title: '数据泄露全网监测平台',
            iconColor: '#fff',
            iconBgColor: '#333',
            width: 400,
            height: 300,
            innerLink: true,
            keepInDock: true,
            url: 'https://x.sss.shining-data.com/'
            // url: 'http://localhost:18081/'
        },
        {
            key: 'sd_xiaoyi_ai',
            component: 'DemoAi',
            icon: '',
            logo: '/logo/sssc.png',
            title: '数据安全运营AI智脑',
            iconColor: '#fff',
            iconBgColor: '#333',
            width: 400,
            height: 300,
            innerLink: true,
            keepInDock: true,
            url: 'https://ai.sss.shining-data.com/login'
            // url: 'http://localhost:18083/login'
        },
        {
            key: 'sd_IntelligenceSystem',
            component: 'DemoIntelligenceSystem',
            icon: 'icon-Send',
            title: '数据情报智能决策系统',
            iconColor: '#00ac97',
            iconBgColor: '#333',
            width: 400,
            height: 300,
            innerLink: true,
            keepInDock: true,
            url: 'https://f.sss.shining-data.com/login'
            // url: 'http://localhost:18084/login'
        },
        {
            key: 'system_setting',
            component: 'SystemSetting',
            icon: 'icon-setting-fill',
            title: '应用设置',
            iconColor: '#00ac97',
            iconBgColor: '#333',
            width: 400,
            height: 600,
            hideInDesktop: true,
            keepInDock: true
        }
    ]
};
